import StorageExpiration from './helpers.StorageExpiration.ts';

export interface IStorageHelperOptions {
    global?: boolean;
    expirationDays?: number;
}

class StorageHelper {
    private storage: Storage;
    private expiration: StorageExpiration;

    public constructor(storage: Storage) {
        if (!(storage instanceof Storage)) {
            throw new TypeError('Expected storage that extends Storage');
        }

        this.storage = storage;
        this.expiration = new StorageExpiration(this.storage);
        this.expiration.removeExpiredItems();
    }

    public get length(): number {
        return this.storage.length;
    }

    public key(index: number): string | null {
        return this.storage.key(index);
    }
    
    public getItem(
        key: string,
        options: IStorageHelperOptions = {},
        defaultValue: string = ''
    ): string {
        const storageKey = this.storageKey(key, options);
        const value = this.storage.getItem(storageKey);

        this.expiration.update(storageKey);

        return value ?? this.setItem(key, defaultValue, options);
    }
    
    public setItem(
        key: string,
        value: string,
        options: IStorageHelperOptions = {}
    ): string {
        const storageKey = this.storageKey(key, options);
        const { expirationDays } = options;

        this.storage.setItem(storageKey, value);
        this.expiration.add(expirationDays, storageKey);

        return value;
    }
    
    public removeItem(key: string, options: IStorageHelperOptions = {}) {
        const storageKey = this.storageKey(key, options);

        this.storage.removeItem(storageKey);
        this.expiration.delete(storageKey);
    }
    
    public clear(options: IStorageHelperOptions = {}) {
        const keyStart = this.storageKey(null, options);
        const keysToRemove = Object.keys(this.storage).filter((key) =>
            key.startsWith(keyStart)
        );

        keysToRemove.forEach((key) => this.storage.removeItem(key));
        this.expiration.delete(...keysToRemove);
    }

    private storageKey(
        key: string | null,
        options: IStorageHelperOptions = {}
    ): string {
        const global = options.global ?? false,
            prefix = global ? 'global' : 'local',
            pathName = window.location.pathname,
            pathSegments = pathName.split('/'),
            appId = global
                ? null
                : pathSegments.length === 0
                ? null
                : pathSegments[pathSegments.length - 1];

        return [prefix, appId, key]
            .filter((subString) => subString !== null)
            .join('.');
    }

    public getStorageKey(key: string, options?: IStorageHelperOptions) {
        return this.storageKey(key, options);
    }
}

class LocalStorageHelper extends StorageHelper {
    constructor() {
        super(window.localStorage);
    }
}

class SessionStorageHelper extends StorageHelper {
    constructor() {
        super(window.sessionStorage);
    }
}

const localStorageHelper = new LocalStorageHelper();
const sessionStorageHelper = new SessionStorageHelper();

export {
    localStorageHelper as default,
    localStorageHelper,
    sessionStorageHelper,
    LocalStorageHelper,
    SessionStorageHelper,
};
